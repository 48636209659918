import { render, staticRenderFns } from "./ViewAllAnnouncementClosed.html?vue&type=template&id=f3b05dae&scoped=true&"
import script from "./ViewAllAnnouncementClosed.vue?vue&type=script&lang=js&"
export * from "./ViewAllAnnouncementClosed.vue?vue&type=script&lang=js&"
import style0 from "./ViewAllAnnouncementClosed.scss?vue&type=style&index=0&id=f3b05dae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3b05dae",
  null
  
)

export default component.exports