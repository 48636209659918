<template src="./ViewAllAnnouncementClosed.html"></template>

<script>
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import announcementService from "../../services/Announcement";
import { mapGetters } from "vuex";
import { roleName } from "@/utils/constants";

export default {
  name: "ViewAllAnnouncementClosed",
  components: {
    ArrobaMedellinArrobitaNoData,
    ChooseUniversity,
  },
  props: {
    title: {
      type: String,
    },
    TitleNotFound: {
      type: String,
      default: "Convocatorias",
    },
    notFound: {
      type: String,
      default: "No tienes convocatorias abiertas en este momento",
    },
  },
  data() {
    return {
      buttonText: "Ver información",
      closedCalls: [],
      role: "",
      institutionId: "",
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
    this.getClosedCalls();
  },
  methods: {
    async getClosedCalls() {
      try {
        if (this.role === roleName.ADMINISTRATOR_CALL) {
          const objClosedCalls = {
            take: 50,
            skip: 0,
            state: false,
          };
          this.closedCalls = await announcementService.listAnnouncement(
            objClosedCalls
          );
        } else {
          const objClosedCalls = {
            state: false,
            institution: this.institutionId,
            publicState: "both",
          };
          this.closedCalls =
            await announcementService.announcementByStateAndInstitution(
              objClosedCalls
            );
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidation() {
      return ["Student", "Teacher", "Entrepreneur", "Administrator"].some(
        (a) => a === this.role
      );
    },
    ...mapGetters({
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.getClosedCalls();
    },
  },
};
</script>

<style scoped lang="scss" src="./ViewAllAnnouncementClosed.scss"></style>